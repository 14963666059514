import React, {FC} from 'react'
import {NavLink, Link} from 'react-router-dom'
import {routeConfig} from '../App'

interface SubscribeBadgeConfig {
  title: string;
  imgUrl: string;
  linkTo: string;
  style?: any;
}

const subscribeBadgeConfigs: SubscribeBadgeConfig[] = [
  {
    title: 'Apple Podcasts',
    imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Podcasts_%28iOS%29.svg/240px-Podcasts_%28iOS%29.svg.png',
    linkTo: 'https://podcasts.apple.com/us/podcast/boys-with-bed-frames/id1550877539',
  },
  {
    title: 'Overcast',
    imgUrl: 'https://overcast.fm/img/appicon-3200.png',
    linkTo: 'overcast://x-callback-url/add?url=https://overcast.fm/itunes1550877539'
  },
  {
    title: 'Spotify',
    imgUrl: 'https://developer.spotify.com/assets/branding-guidelines/icon3@2x.png',
    linkTo: 'https://open.spotify.com/show/5Yp6nWfplgcF6qmQu8zadQ',
    style: {transform: "scale(1.5)"}
  },
  {
    title: 'Pocket Casts',
    imgUrl: 'https://is1-ssl.mzstatic.com/image/thumb/Purple123/v4/90/37/62/903762cf-fd41-e0dc-c807-cf35cfd627d3/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/246x0w.png',
    linkTo: 'https://pca.st/itunes/1550877539'
  },
  // TODO: Revisit which icons to show. Can't show that many, otherwise it'd have to wrap on mobile
  // {
  //   title: 'Castro',
  //   imgUrl: 'https://castro.fm/assets/images/Bitmap.svg',
  //   linkTo: 'https://castro.fm/itunes/1550877539'
  // },
  {
    title: 'RSS',
    imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Generic_Feed-icon.svg/240px-Generic_Feed-icon.svg.png',
    linkTo: 'https://pinecast.com/feed/boys-with-bed-frames'
  }
];

interface HeaderProps {
  routesConfig: routeConfig[];
  artworkUrl: string;
}

const showNav = false;

const Header: FC<HeaderProps> = ({routesConfig, artworkUrl}) => {
  return (
    <>
      <div className='app-header'>
        <div className="title-header">
          <Link className="title" to="/">Boys With Bed Frames</Link>
          <div className="subtitle">
            Ever wondered what would happen if all the animals along the equator were capable of flattery?
            Hosted by <a href='https://www.tylerpostigli.one/' target='_blank' rel='noopener noreferrer'>Tyler Postiglione</a> and <a
            href={'https://en.wikipedia.org/wiki/Phil_Collins'} target='_blank' rel='noopener noreferrer'>Santiago (Santi) Hervella</a>
          </div>
          <div className='subscribe'>
            <div className='subscribe-title'>Listen for free in your favorite podcast app:</div>
            <div className='subscribe-badges'>
              {subscribeBadgeConfigs.map((badge) => (
                <a className='subscribe-badge' href={badge.linkTo} target='_blank' rel='noopener noreferrer' key={badge.title}>
                  <div className='img-container'>
                  <img className='badge-img' src={badge.imgUrl} alt={badge.title} style={badge.style}/>
                  </div>
                  <span className='app-name'>{badge.title}</span>
                </a>
              ))}
            </div>
          </div>
        </div>
        <a className='landing' href={subscribeBadgeConfigs[0].linkTo} target='_blank' rel='noopener noreferrer'>
          {
            artworkUrl ?
              <img className='artwork' src={artworkUrl} alt='Boys With Bed Frames artwork'/>
              : null
          }
        </a>
      </div>
      {showNav ? <nav className='nav-bar'>
        <ul>
          {routesConfig.map((routeConfig) => (
            <li>
              <NavLink exact activeClassName="selected" to={routeConfig.path}>
                {routeConfig.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav> : null}
    </>
  )
}

export default Header
