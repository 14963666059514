import {Route, Redirect, useLocation} from "react-router-dom";
import Feed, {Episode} from "./Components/Feed";
import React, {FC} from "react";

interface RoutesProps {
  episodes: Episode[];
}

export const Routes: FC<RoutesProps> = (props) => {
  const {episodes} = props;
  const {pathname} = useLocation();

  // TODO: See if there's a slightly cleaner way to do this sorting
  const sortedEpisodes = episodes.sort((a, b) => a.title > b.title ? -1 : 1);
  const pathParamEpisodeNumber: any = pathname.substring(1);
  const isEpisodeNumber = pathParamEpisodeNumber && !isNaN(pathParamEpisodeNumber);
  const episodesToRender: Episode[] =
    isEpisodeNumber && sortedEpisodes.length > 0
      ? [sortedEpisodes[sortedEpisodes.length - parseInt(pathParamEpisodeNumber)]]
      : sortedEpisodes;

  return <>
    <Route
      exact
      path={'/'}
      render={
        (props) => (
          <Feed episodes={sortedEpisodes} {...props}/>
        )
      }
    />
    <Route
      path={'*'}
    >
      {isEpisodeNumber ? <Feed episodes={episodesToRender}/> : <Redirect to='/'/>}
    </Route>
  </>
}

// TODO: Re-implement the other routes
// {/* {routesConfig.map((routeConfig) => {
//           const { path, component} = routeConfig;
//           return (
//             <>
//             <Route exact path={path} render={(props) => (<component {...props} isAuthed={true} />)}/>
//             </>
//           )})} */}