import React from 'react';

const Feedback = () => {
  return (
    <div className='feedback'>
      <h1>
        Feedback
      </h1>
      <h3>
        How to submit
      </h3>
      <p>
        Here is some placeholder text that will explain to listeners how they can submit feedback.
        Perhaps in this paragraph we would point them to some link or give them an email address?
      </p>
    </div>
  )
};

export default Feedback;