import React, {FC} from 'react';
import parse from 'html-react-parser';
import {DateTime} from 'luxon';

export interface Episode {
  title: string,
  content: string,
  isoDate: string,
  itunes: any,
  enclosure: any,
  guid: string
}

interface FeedProps {
  episodes: Episode[];
}

const pinecastWatermarkHTML = `<p>This podcast is powered by <a href="https://pinecast.com" rel="nofollow">Pinecast</a>.</p>`;
const anchorRelRegex = /rel="nofollow"/gm;
const newTabHtml = `target='_blank' rel='noopener noreferrer'`;

const Feed: FC<FeedProps> = (props) => {
  const {episodes} = props;
  
  return (
    <main className='feed'>
      {episodes ? <div>
        {episodes.map((episode, index) => {
          const {title, isoDate, itunes, enclosure, content, guid} = episode;
          const formattedDate = DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_FULL);
          const formattedContent = content.replace(pinecastWatermarkHTML, '').replace(anchorRelRegex, newTabHtml);
          
          return (
            <article className='post' key={guid} id={`${episodes.length - index}`}>
              <h2>{title}</h2>
              <div className='metadata'>
                <time dateTime={isoDate}>
                  {formattedDate}
                </time>
                <div>
                  {itunes.duration} • <a href={enclosure.url} download>Download</a>
                </div>
              </div>
              <audio controls src={enclosure.url}/>
              <div className="subtitle">
                {itunes.subtitle}
              </div>
              <div className='show-notes'>
                <h3>Show Notes:</h3>
                {parse(formattedContent)}
              </div>
            </article>
          )
        })}
      </div> : null}
    </main>
  )
};

export default Feed;

// TODO: Show the size of the file? Maybe not needed...
// check out: https://github.com/dimdenGD/url-file-size/blob/main/index.js
// check out: https://www.npmjs.com/package/remote-file-size
// Note: Seems this should be done using a HEAD request
// check out: https://nodejs.org/api/http.html#http_http_request_url_options_callback
// useEffect(() => {
//   request(
//     {
//       method: 'HEAD',
//       host: 'https://traffic.libsyn.com/atpfm/atp415.mp3',
//       hostname: 'https://traffic.libsyn.com/atpfm/atp415.mp3'
//     }, (res) => {
//     console.log({res});
//   }).on('error', (err) => {
//     console.error({err});
//   }).end();
// }, []);